import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import { CheckCircleIcon, Trash2 } from "lucide-react";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Button, Progress } from "@dewrangle/ui";

import { FileUploadProgress } from "@/components/studyFile";
import { Layout } from "@/components/uikit";
import { formatFileSize } from "@/lib/string";

interface FileDropzoneProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
  multiple?: boolean;
  accept?: Record<string, string[]>;
  className?: string;
  showFileList?: boolean;
  disabled?: boolean;
  label?: string;
  uploadProgress?: Record<string, FileUploadProgress>;
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  files,
  onFilesChange,
  multiple = false,
  accept,
  className,
  showFileList = true,
  disabled = false,
  label = "Drag n drop or click to select files!",
  uploadProgress = {},
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesChange([...files, ...acceptedFiles]);
    },
    [files, onFilesChange]
  );

  const removeFile = useCallback(
    (indexToRemove: number) => {
      onFilesChange(files.filter((_, index) => index !== indexToRemove));
    },
    [files, onFilesChange]
  );

  // The root props are helpful for passing down to other components
  const { getRootProps, getInputProps } = useDropzone({
    accept, // Uses this format https://developer.mozilla.org/en-US/docs/Web/API/Window/showOpenFilePicker#types
    multiple,
    onDrop,
    disabled,
  });

  return (
    <div className={className}>
      <div
        className="flex flex-1 flex-col items-center p-8 border-4 border-dashed border-primary/20 bg-primary/5 text-primary"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>{label}</p>
      </div>

      {showFileList && files.length > 0 && (
        <aside>
          <ul className="mt-4 space-y-2">
            {files.map((file, index) => {
              const fileProgress = uploadProgress[file.name];
              const showProgress =
                fileProgress && fileProgress.status === "uploading";
              const showError = fileProgress && fileProgress.status === "error";

              return (
                <li key={index} className="border-b pb-2 last:border-b-0">
                  <Layout.Row className="justify-between">
                    <Layout.Row className="gap-x-2">
                      <div className="break-words">
                        {file.name} - {formatFileSize(file.size)}
                      </div>
                      {fileProgress?.status === "success" && (
                        <CheckCircleIcon size={16} className="text-success" />
                      )}
                    </Layout.Row>
                    <Button
                      aria-label={`Clear ${file.name}`}
                      onClick={() => removeFile(index)}
                      variant="ghost"
                      size="icon"
                      type="button"
                      disabled={disabled || showProgress}
                    >
                      <AccessibleIcon label="Remove file">
                        <Trash2 size={16} />
                      </AccessibleIcon>
                    </Button>
                  </Layout.Row>

                  {showProgress && (
                    <div className="mt-1">
                      <Progress
                        value={fileProgress.progress}
                        className="h-2"
                        aria-label={`Uploading ${file.name}: ${fileProgress.progress}%`}
                      />
                      <p className="text-xs text-muted-foreground mt-1 text-right">
                        {fileProgress.progress}%
                      </p>
                    </div>
                  )}

                  {showError && (
                    <p className="text-xs text-destructive mt-1">
                      Failed to upload file
                    </p>
                  )}
                </li>
              );
            })}
          </ul>
        </aside>
      )}
    </div>
  );
};
