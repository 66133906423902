import _pick from "lodash/pick";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { clsxm } from "@peykio/clsxm";

export const Link = NextLink;

export type IQuickLinkProps = {
  pick?: string[];
  disabled?: boolean;
  activeClass?: string;
} & Parameters<typeof Link>[0];

export const QuickLink = React.forwardRef<HTMLAnchorElement, IQuickLinkProps>(
  (
    { href, pick = [], className, disabled, activeClass = "", ...rest },
    ref
  ) => {
    const router = useRouter();
    const pathname =
      (typeof href === "string" ? href : href.pathname) ?? router.pathname;
    // sometimes you need to go up the stack, study page -> org page for example
    // use pick to just keep the params you are interested in
    // otherwise you can end up with a ?bunch=of&query=strings
    const picked = pick.length > 0 ? _pick(router.query, pick) : router.query;
    const query =
      typeof href === "string" || typeof href?.query === "string"
        ? picked
        : { ...picked, ...href?.query };
    const active = pathname === router.pathname && !!activeClass;

    return (
      <Link
        href={{ pathname, query }}
        className={clsxm(
          {
            "opacity-50": disabled,
            "pointer-events-none": disabled || active,
            [activeClass]: active,
          },
          className
        )}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : undefined}
        ref={ref}
        {...rest}
      />
    );
  }
);
