/**
 * @generated SignedSource<<c1990f978c4db81d85c34f84d8bc16b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_NavigationHeaderWithVolumeQuery$variables = {
  volume: string;
};
export type Navigation_NavigationHeaderWithVolumeQuery$data = {
  readonly volume: {
    readonly name?: string;
    readonly study?: {
      readonly name: string;
      readonly organization: {
        readonly name: string;
      };
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_NavigationProfileDropdown">;
};
export type Navigation_NavigationHeaderWithVolumeQuery = {
  response: Navigation_NavigationHeaderWithVolumeQuery$data;
  variables: Navigation_NavigationHeaderWithVolumeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "volume"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "volume"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Navigation_NavigationHeaderWithVolumeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Navigation_NavigationProfileDropdown"
      },
      {
        "alias": "volume",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Volume",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Navigation_NavigationHeaderWithVolumeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "volume",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Volume",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94ad5de8ee4ee3a813868e6346efd8b0",
    "id": null,
    "metadata": {},
    "name": "Navigation_NavigationHeaderWithVolumeQuery",
    "operationKind": "query",
    "text": "query Navigation_NavigationHeaderWithVolumeQuery(\n  $volume: ID!\n) {\n  ...Navigation_NavigationProfileDropdown\n  volume: node(id: $volume) {\n    __typename\n    ... on Volume {\n      name\n      study {\n        name\n        organization {\n          name\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment Navigation_NavigationProfileDropdown on Query {\n  viewer {\n    image\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "751cf17e882e7488a22b27ea48b58718";

export default node;
